import { http } from './config'
import authHeader from './auth-header';

export default {


    

    total_carne_entregue: (dataI, dataF, nomeEmpresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
        }
        return http.get('/faturamento-dash/total-carne-entregue/', {
           params: params,
           headers: authHeader()   
        })
    },


    lista_cane_cidade: (dataI, dataF, nomeEmpresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
        }
        return http.get('/faturamento-dash/lista-cane-cidade/', {
           params: params,
           headers: authHeader()   
        })
    },

    lista_cane_cidade_entregue: (dataI, dataF, nomeEmpresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
        }
        return http.get('/faturamento-dash/lista-cane-cidade-entregue/', {
           params: params,
           headers: authHeader()   
        })
    },

     
    lista_cidade_carne_xls: (dataI, dataF, nomeEmpresa) => {

        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': converteParaNull(dataI),
            'data-final': converteParaNull(dataF),
            'empresa': nomeEmpresa
          };

          return http.get('/planilha-dash/lista-carne-cidade-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });
        
    },

    lista_carne_cidade_entregue_xls: (dataI, dataF, nomeEmpresa) => {

        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': converteParaNull(dataI),
            'data-final': converteParaNull(dataF),
            'empresa': nomeEmpresa
          };

          return http.get('/planilha-dash/lista-carne-cidade-entregue-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });
        
    },

    aguardando_entregue: (dataI, dataF, nomeEmpresa) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
        }

        return http.get('/faturamento-dash/total-carne-pendente/', {
           params: params,
           headers: authHeader() 
              
        })
    },


    
    total_carne_nc: (dataI, dataF, nomeEmpresa) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
        }

        return http.get('/faturamento-dash/total-carne-nc/', {
           params: params,
           headers: authHeader() 
              
        })
    },

    
    

   

}
